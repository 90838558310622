import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, CanDeactivate, CanDeactivateFn} from '@angular/router';
import {Observable} from 'rxjs';
import { AuthService } from './auth.service';


@Injectable()
export class UserGuard implements CanActivate, CanDeactivate<any> {
  constructor( private router: Router, public authservice: AuthService) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean>|boolean {
        if(window.sessionStorage['ilogged']==='ilogged') {
            return true;
        } else {
            this.router.navigate(['docintake/eAudit']);
            return false;
        }
  }
  canDeactivate(component: any) {
    console.log('deactiveed called');
    // will prevent user from going back
    if (this.authservice.getBackClicked()) {
      this.authservice.setBackClicked(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }
}
