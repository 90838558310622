import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EauditLandingPageComponent } from './eAudit-landing-page/eaudit-landing-page.component';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { UserGuard } from './services/userguard.service';
import {EmployerformComponent } from './employerform/employerform.component'

const routes: Routes = [
   {
    path: '',
    redirectTo: 'docintake/eAudit',
    pathMatch:'full'
  },
  {
    path: 'docintake/eAudit',
    canDeactivate: [UserGuard],
    component: EauditLandingPageComponent,
  },
  {
    path: 'docintake/eAuditemployerform',
    canActivate: [UserGuard],
    component: EmployerformComponent,
  },
  {
    path: 'docintake/eAuditfileUpload',
    canActivate: [UserGuard],
    component: FileuploadComponent,
  },
   {
    path: 'docintake/Confirmation',
    canActivate: [UserGuard],
    component: ConfirmationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
