import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';


@Injectable()
export class UserGuard implements CanActivate {
  constructor( private router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean>|boolean {
        if(window.localStorage['ilogged']==='ilogged') {
            return true;
        } else {
            this.router.navigate(['docintake/eAudit']);
            return false;
        }
  }
}
