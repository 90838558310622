import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthInterceptor } from '../auth.interceptor';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenUrl = environment.authUrl;
  authFailed = new BehaviorSubject<boolean>(false);
  backbuttonclicked:boolean = false;

  
 


  constructor(private http: HttpClient) {}

  async fetchToken(): Promise<boolean> {
    console.log(' fetching new token....');
    try {
      const data: any = await this.http.get(this.tokenUrl).toPromise();
      if (data.token) {
        sessionStorage.setItem('bearer', data.token);
        this.authFailed.next(false); 
        return data.token; //token fetch sucess 
      }
    } catch (error) {
      console.log('error fetching token', error);
      this.authFailed.next(true); //auth failed
    }
    return false; // token fetch failed
  }

  async ensureTokenIsValid(): Promise<boolean> {
    const token = this.getToken();
    if (!token || this.isTokenInvalid(token)) {
      console.warn('Token is expired or missing. fetching a new one..');
      //remove the old token before fetching new token
      this.logout();
      return await this.fetchToken(); // fetch new token
    }
    return true; // token valid
  }

  isTokenInvalid(token: string): boolean {
   if (token === '0') { // 0 means expired
    console.warn('Invalid token ');
    return true;
   }
    return  false; 
  }
  
  getToken(): string | null {
    return sessionStorage.getItem('bearer')
  }

  logout(): void {
    sessionStorage.removeItem('bearer');
     this.authFailed.next(true);

  }

  isAuthFailed() {
    return this.authFailed.asObservable();
  }

  getBackClicked() {
    return this.backbuttonclicked;
  }

  setBackClicked(status) {
    this.backbuttonclicked = status;
  }
}
