import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable, catchError, map, throwError, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private baseUrl = environment.uploadURL;
  private submitDataUrl = environment.submissionUrl;
  public currentIpAddress = '';

  private employeerFormData = new BehaviorSubject<any>(null);
  currentFormData = this.employeerFormData.asObservable();

  updateEmployeerFormData(data: any) {
    this.employeerFormData.next(data);
  }

  clearEmployeerFormData() {
    this.employeerFormData.next(null);
  }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'X-Frame-Options': 'SAMEORIGIN',
      // 'Content-Security-Policy': "frame-ancestors 'self'",
      'timeout': 36000
    })
  };


  constructor(private http: HttpClient) {
    this.http.get('https://api.ipify.org?format=json').subscribe((data: any) => {
      this.currentIpAddress = data.ip;
    });
  }

  toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });


  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }


  upload(file: any): Promise<any> {
    //const fileContent: any = await this.toBase64(file);
    //console.log (fileContent);
    let fileData = {
      browserDetails: this.getBrowserName(),
      guid: "",
      IpAddress: this.currentIpAddress,
      userId: window.sessionStorage['caseid'],
      fileName: file.name,
      businessEntity: "eaudit",
      fileContent: file.fileContent.split(',')[1],

    }


    // });
 
    // });
    return this.http.post(`${this.baseUrl}/uploadSingleDocument`, fileData, {
      reportProgress: true,
      observe: 'events',
      ...this.httpOptions

    }).pipe(timeout(36000), map(response => {
      if (response.type === HttpEventType.UploadProgress) {
        file.progress = Math.round((100 * response.loaded) / response.total);
      }
      return response;
    }),
      // return this.http.request(req).pipe(timeout(36000),  map(response => {
      //   return response;
      // }),
      catchError(error => {
        error.currenObj = file;
        return throwError(error);
      })).toPromise();

  }

  submitData(data: any): Observable<any> {
    let newFileIds = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        newFileIds.push(data[i].newFileName)
      }
    }

    const storedOfficerDetails = sessionStorage.getItem("employerInfo");
    const employerDetails = storedOfficerDetails ? JSON.parse(storedOfficerDetails) : {};

    // Get original address from session storage
    const originalAddress = sessionStorage.getItem("addressJson");
    let parsedOriginalAddress = null;
    if (originalAddress) {
      try {
        parsedOriginalAddress = JSON.parse(originalAddress);
      } catch (error) {
        console.error("Error parsing original address:", error);
        parsedOriginalAddress = null;
      }
    }


    let updatedAddressDetails = {
      addresdesc: employerDetails.addresdesc || '',
      addrtype: employerDetails.addrtype || '',
      city: employerDetails.city,
      country: employerDetails.country,
      addrline1: employerDetails.address1,
      addrline2: employerDetails.address2,
      state: employerDetails.state,
      zip: employerDetails.zip,
      zipextn: employerDetails.zipExt
    };

    console.log('parsedoriginal', parsedOriginalAddress);
    console.log('updated', updatedAddressDetails);

    // Check if the address has changed using  comparison
    let isAddressChanged = parsedOriginalAddress ? !this.areAddressesEqual(parsedOriginalAddress, updatedAddressDetails) : true

    let fileData = {
      userName: 'DOLExternalWebApp',
      request: {
        domainName: "eaudit",
        caseId: window.sessionStorage['caseid'],
        ern: window.sessionStorage['erNumber'],
        browserName: this.getBrowserName(),
        ipAddress: this.currentIpAddress,
        fileIds: newFileIds,
        notes: employerDetails.notes || "",
        principalOfficerDetails: {
          principalOfficerName: employerDetails.principalOfficerName || "",
          principalOfficerTitle: employerDetails.title || "",
          principalEmailAddress: employerDetails.principalEmail || "",
          principalPhoneNumber: employerDetails.principalPhone || "",
        },
        repDetails: {
          repOfficerName: employerDetails.repName,
          repEmailAddress: employerDetails.repEmail,
          repPhoneNumber: employerDetails.repPhone,
        },
      },
    }
    if (isAddressChanged) {
      fileData.request["address"] = JSON.stringify(updatedAddressDetails);
    }

    if (sessionStorage.getItem("addressJson") === null) {
      fileData.request["address"] = JSON.stringify(updatedAddressDetails);
    }
    // verify if addressJson is included or not
    console.log("Original Address", JSON.stringify(parsedOriginalAddress));
    console.log("Updated Addres", JSON.stringify(updatedAddressDetails));
    console.log(" Address Changed", isAddressChanged);
    console.log("Final Payload ", fileData);


    // Compare fields one by one to see the exact difference
    for (const key in parsedOriginalAddress) {
      if (parsedOriginalAddress[key] !== updatedAddressDetails[key]) {
        console.log(`Mismatch in field "${key}":`);
        console.log("Original:", parsedOriginalAddress[key]);
        console.log("Updated:", updatedAddressDetails[key]);
      }
    }

    return this.http.post(this.submitDataUrl + '/submit', fileData, this.httpOptions).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }



  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }

  deleteFile(file: any): Observable<any> {
    return this.http.delete(`${this.baseUrl}/files/` + file.name);
  }

  //  Comparison Function
  private areAddressesEqual(addr1: any, addr2: any): boolean {

    const keys = ["addrline1", "addrline2", "city", "state", "country", "zip", "zipextn"];
    for (const key of keys) {
      let value1 = addr1[key] ?? ""; // Convert null/undefined to empty string
      let value2 = addr2[key] ?? "";
      if (typeof value1 === "string") value1 = value1.trim().toLowerCase();
      if (typeof value2 === "string") value2 = value2.trim().toLowerCase();
      if (value1 !== value2) {
        console.log(`Mismatch found in "${key}": "${value1}" vs "${value2}"`);
        return false;
      }
    }
    return true;
  }
}