import { Component, ElementRef, ViewChild } from '@angular/core';
import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { FileUploadService } from '../services/file-upload.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { EAuditLandingPageService } from '../services/eAudit-landing-page.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, catchError, forkJoin, of, tap } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { ZipUnzipinfoComponent } from '../zip-unzipinfo/zip-unzipinfo.component';
@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss'],
})
export class FileuploadComponent {
  selectedFiles?: FileList;
  currentFile?: any;
  progress = 0;
  message = '';
  fileInfos: any[] = [];
  uploadedFiles: any[] = [];
  triggeredAPIs:any[] = [];
  uploadedFully:boolean = true;
 
  errorSubmitMsg: string | undefined;
  fileSelected100MB: boolean = false;
  successMessage: string | undefined;
  disableUploadError: boolean = false;
  errorMessages: string[] = [];
  errorOccured = 0;
  errorReoccured = 0;
  reCAPTCHAVisible: boolean = false;
  reCAPTCHAToken: string = '';
  recaptchaError: boolean;
  errorSubmitMsgrecaptcha: string;
  totlaFilesUploaded: any;
  failedFiles: any = [];
  recalculateMetrics: any = [];
  uploadInProgress: boolean;

  fileSupported: String;
  maxFileCount: number;
  maxFilePerUpload: number;
  perUploadMaxFileCount: number;
  maxFileSizeMB: number;
  // total size 
  maxTotalSizeMB: number;
  //min 20 mb
  perUploadMaxSmallFileSizeMB: number;
  perUploadMaxSmallFileCnt: number;
  //smallfilestotalsize
  perUploadMaxSmallFileTotalSizeMB: number;
  //max 100mb 
  perUploadMaxLargeSizeMB: number;
  //per upload allowed 1 large file
  perUploadMaxLargeFileCnt: number;
  perUploadmaxLargeFileTotalSizeMB: number;
  totalLargeFilesUploaded: number =0;
   // Variables to track the cumulative total sizes of files
  cumulativeSmallFileSizeMB : number = 0;  // Track cumulative small file size
  cumulativeLargeFileSizeMB  : number = 0;  // Track cumulative large file size
  cumulativeTotalFileSizeMB :  number =0;
  totalSmallFilesUploaded : number = 0;
  totalFileCount: number;

  waitTillUpload:boolean = false;
  maxUploadPerHourMB: number;
 
 
  constructor(
    private uploadService: FileUploadService,
    private router: Router,
    private location: Location,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private eAuditService: EAuditLandingPageService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

ngOnInit(): void {
    this.generateRecaptchaToken();
    this.fileSupported = sessionStorage.getItem('fileSupported') || environment.fileSupported; //file format
    this.maxFileCount =  Number(sessionStorage.getItem('maxFileCount')) || Number(environment.maxFileCount); //50 files
    this.perUploadMaxFileCount = Number(sessionStorage.getItem('perUploadMaxFileCount')) || Number(environment.perUploadMaxFileCount)  //10 files
    this.maxTotalSizeMB = Number(sessionStorage.getItem('maxTotalSizeMB')) || Number(environment.maxTotalSizeMB);//1400 (OVERALL TOTAL)
    this.maxFileSizeMB = Number(sessionStorage.getItem('maxFileSizeMB')) || Number(environment.maxFileSizeMB);//each file size 100mb 
    this.perUploadMaxSmallFileSizeMB = Number(sessionStorage.getItem('perUploadMaxSmallFileSizeMB')) || Number(environment.perUploadMaxSmallFileSizeMB); //20MB
    this.perUploadMaxSmallFileTotalSizeMB = Number(sessionStorage.getItem('perUploadMaxSmallFileTotalSizeMB')) || Number(environment.perUploadMaxSmallFileSizeMB); //900 MB
    this.perUploadMaxSmallFileCnt = Number(sessionStorage.getItem('perUploadMaxSmallFileCnt')) || Number(environment.perUploadMaxSmallFileCnt); //(45 ) smallfiles
    this.perUploadMaxLargeSizeMB = Number(sessionStorage.getItem('perUploadMaxLargeSizeMB'))  || Number(environment.perUploadMaxLargeSizeMB); //above 20mb - 100MB
    this.perUploadmaxLargeFileTotalSizeMB = Number(sessionStorage.getItem('perUploadmaxLargeFileTotalSizeMB')) || Number(environment.perUploadmaxLargeFileTotalSizeMB); //500 MB
    this.perUploadMaxLargeFileCnt = Number(sessionStorage.getItem('perUploadMaxLargeFileCnt')) || Number(environment.perUploadMaxLargeFileCnt); //1
    this.maxUploadPerHourMB = Number(sessionStorage.getItem('maxUploadPerHourMB'))
   
   
  }

 openModal(event: Event): void { 
 const modalRef = this.modalService.open(ZipUnzipinfoComponent);
 modalRef.result.then((result) => {

 })
 }

  generateRecaptchaToken() {
    this.recaptchaV3Service.execute('importantAction').pipe(
      tap((token) => {
        this.reCAPTCHAToken = token;
        this.recaptchaError = false;
      }),
      catchError((error) => {
        console.error('reCAPTCHA error:', error);
        this.handleRecaptchaError(error);
        return of(null);
      })
    ).subscribe();
   }
   // Handle reCAPTCHA errors
   handleRecaptchaError(error: any) {
    console.error('reCAPTCHA error:', error);
      this.errorSubmitMsgrecaptcha = "The system is currently unavailable. Please try again later.";
       this.recaptchaError = true;
   }

  selectFile(event: any): void {
   this.selectedFiles = event.target.files;
   this.reinitialiseValues();
   this.checkAllErrors();
  }

  reinitialiseValues() {
    if (this.fileInfos.length > 0) {
      this.totalSmallFilesUploaded = 0;
      this.cumulativeSmallFileSizeMB = 0;
      this.cumulativeLargeFileSizeMB = 0;

      let selectedFilesArray = Array.from(this.fileInfos);
     
      //filter small files (<=20MB)
      const smallFiles = selectedFilesArray.filter(file => file.size <= this.perUploadMaxSmallFileSizeMB *1024 *1024);

      //filter large files (>20MB BUT <=100MB)
      const largeFiles = selectedFilesArray.filter(file => file.size > this.perUploadMaxSmallFileSizeMB *1024 *1024 
        && file.size <= this.perUploadMaxLargeSizeMB *1024 *1024);


    //calculte the total size of all selected files(mb)
    this.totalLargeFilesUploaded += largeFiles.length;
    this.totalSmallFilesUploaded += smallFiles.length;
      
      const totalLargeFileSizeMB = largeFiles.reduce((total, file) => total + file.size / (1024 * 1024), 0);
      const totalSmallFileSizeMB = smallFiles.reduce((total, file) => total + file.size / (1024 * 1024), 0);
  
      // Step 3: Track the cumulative total size of small and large files
      this.cumulativeSmallFileSizeMB += totalSmallFileSizeMB; // Adding the size of small files being uploaded to the cumulative total
      this.cumulativeLargeFileSizeMB += totalLargeFileSizeMB; // Adding the size of large files being uploaded to the cumulative total
 
    } else {
      this.totalSmallFilesUploaded = 0;
      this.cumulativeSmallFileSizeMB = 0;
      this.cumulativeLargeFileSizeMB = 0;
    } 
  }
 
  checkAllErrors(): void {
    this.disableUploadError = false;
    this.fileSelected100MB = false;
    this.message = '';
    this.errorSubmitMsg = undefined;
    this.errorMessages = [];
    this.recalculateMetrics = [];

    if (!this.selectedFiles) {
      return;
    }

    let selectedFilesArray = Array.from(this.selectedFiles);

    //filter small files (<=20MB)
    const smallFiles = selectedFilesArray.filter(file => file.size <= this.perUploadMaxSmallFileSizeMB *1024 *1024);

    //filter large files (>20MB BUT <=100MB)
    const largeFiles = selectedFilesArray.filter(file => file.size > this.perUploadMaxSmallFileSizeMB *1024 *1024 && file.size <= this.perUploadMaxLargeSizeMB *1024 *1024);

    //calculte the total size of all selected files(mb)
    this.totalLargeFilesUploaded += largeFiles.length;
    this.totalSmallFilesUploaded += smallFiles.length;
    

    const maxTotalSizeMB = selectedFilesArray.reduce((total, file) => total + (file.size/ (1024 * 1024)), 0);
    const totalLargeFileSizeMB = largeFiles.reduce((total, file) => total + file.size / (1024 * 1024), 0);
    const totalSmallFileSizeMB = smallFiles.reduce((total, file) => total + file.size / (1024 * 1024), 0);
    
   // Step 3: Track the cumulative total size of small and large files
    this.cumulativeSmallFileSizeMB += totalSmallFileSizeMB; // Adding the size of small files being uploaded to the cumulative total
    this.cumulativeLargeFileSizeMB += totalLargeFileSizeMB; // Adding the size of large files being uploaded to the cumulative total

     //10 files batch
     if (this.selectedFiles.length > this.perUploadMaxFileCount) {
      this.errorMessages.push(`File Upload Limit: Please upload a maximum of ${this.perUploadMaxFileCount} files at a time.`);
      this.disableUploadError = true;
       return ;
     }
     
 if (this.cumulativeLargeFileSizeMB > this.perUploadmaxLargeFileTotalSizeMB   ) {
 this.cumulativeLargeFileSizeMB -= totalLargeFileSizeMB;
  } 

//large file count (limit 50)
let totalLargeFilesCount = this.fileInfos.filter(file => file.size > this.perUploadMaxSmallFileSizeMB * 1024 * 1024 && file.size <= this.perUploadMaxLargeSizeMB * 1024 * 1024).length;
totalLargeFilesCount += largeFiles.length;
//let errorMessage = '';
if (largeFiles.length > this.perUploadMaxLargeFileCnt || totalLargeFilesCount > this.maxUploadPerHourMB) {
let errorMessage = '';
  if (largeFiles.length > this.perUploadMaxLargeFileCnt) {
      errorMessage += `Only ${this.perUploadMaxLargeFileCnt} file between 20MB to 100MB is allowed per upload.`;
      this.errorMessages.push(errorMessage.trim());
      this.disableUploadError = true;
      return;
  }
  if (totalLargeFilesCount > this.maxUploadPerHourMB) {
    errorMessage += `Maximum of ${this.maxUploadPerHourMB} large files per Submission.`;
    this.errorMessages.push(errorMessage.trim());
    this.disableUploadError = true;
  return;
  }
  
}
  
    //fileformat
    for (let i = 0; i < selectedFilesArray.length; i++) {
      const file = selectedFilesArray[i];

    const fileName = file.name.split('.').slice(0,-1).join('.');  
    if (fileName.length > 100) {
      this.errorMessages.push(`The file name uploaded must not exceed 100 characters.`);
      this.disableUploadError = true;
      return;
  }
    
      const fileFormat = file.name.split('.').pop()?.toLowerCase();
 
      if (!fileFormat || this.fileSupported.indexOf(fileFormat) < 0) {
        if (!this.errorMessages.includes(`Please upload a document in one of the accepted file types: ${this.fileSupported}.`)) {
          this.errorMessages.push(
            `Please upload a document in one of the accepted file types: ${this.fileSupported}.`
          );
        } 
        this.disableUploadError = true;
        return;
      }

      // restriction for file size less than or equal to 0kb
      if (file.size <= 0){
        if (!this.errorMessages.includes('Error: Invalid File Size.')) {
          this.errorMessages.push('Error: Invalid File Size.');
        }
        this.disableUploadError = true;
        return;
      }

      // 100 MB
      if (file.size >  (this.maxFileSizeMB * 1024 * 1024)) {
        if (!this.errorMessages.includes(`Please upload document within the file size limit of ${this.maxFileSizeMB} MB.`)) {
          this.errorMessages.push(
            `Please upload document within the file size limit of ${this.maxFileSizeMB} MB.`
          );
        }
        this.fileSelected100MB = true;
        this.disableUploadError = true;
        return;
      }
    }

    const uploaded = this.fileInfos.length;
    const newUploaded = selectedFilesArray.length + uploaded;

  //Step 6: Check combined total file size (small + large) does not exceed the overall limit (1400 MB)
 const totalFileSizeMB = this.cumulativeSmallFileSizeMB + this.cumulativeLargeFileSizeMB;

 // Step 7: Check if the combined total size exceeds 1400 MB
 //if (totalFileSizeMB > this.maxTotalSizeMB) {
  //  if (!this.errorMessages.includes(`Total file size cannot exceed ${this.maxTotalSizeMB} MB.`)) {
  //    this.errorMessages.push(`Total file size cannot exceed ${this.maxTotalSizeMB} MB.`);
  //  }
  // this.disableUploadError = true; // Disable the upload due to the error
// }

//    //Step 4: Check if cumulative small file size exceeds its limit (900 MB)
//     console.log('totlal small files uploaded', this.totalSmallFilesUploaded);
//     console.log('max small file can upload', this.perUploadMaxSmallFileCnt);
//     console.log('check cumltive', this.cumulativeSmallFileSizeMB);
//     console.log('check env small', this.perUploadMaxSmallFileTotalSizeMB);
 //  if (this.cumulativeSmallFileSizeMB > this.perUploadMaxSmallFileTotalSizeMB &&  this.totalSmallFilesUploaded > (this.perUploadMaxSmallFileCnt)) {
//      if (!this.errorMessages.includes(`Total small file size cannot exceed ${this.perUploadMaxSmallFileTotalSizeMB} MB.`)) {
//       this.errorMessages.push(`Total small file size cannot exceed ${this.perUploadMaxSmallFileTotalSizeMB} MB.`);
//    }
  //   this.totalSmallFilesUploaded -= smallFiles.length;
//     this.disableUploadError = true;
//  } 

// //Step 5: Check if cumulative large file size exceeds its limit (500 MB)
 // if (this.cumulativeLargeFileSizeMB > this.perUploadmaxLargeFileTotalSizeMB   ) {
//   if (!this.errorMessages.includes(`Total large file size cannot exceed ${this.perUploadmaxLargeFileTotalSizeMB} MB.`)) {
//     this.errorMessages.push(`Total large file size cannot exceed ${this.perUploadmaxLargeFileTotalSizeMB} MB.`);
//   }
// this.cumulativeLargeFileSizeMB -= totalLargeFileSizeMB;
//  console.log(this.cumulativeLargeFileSizeMB, 'cumulativeLargeFileSizeMB')
//   this.disableUploadError = true;
 // } 




// Track the total size of all selected files (small + large)
// const totalFileSizeMB = totalSmallFileSizeMB + totalLargeFileSizeMB;
// // Check if the combined total size of small and large files exceeds the allowed limit (internally managed)
// if (totalFileSizeMB > this.maxTotalSizeMB) {
//    this.errorMessages.push(`Total file size cannot exceed ${this.maxTotalSizeMB} MB.`);
//    this.disableUploadError = true;
//    return;
// }
   
 

    //50 files per submission
    if (newUploaded > this.maxFileCount) {
      if (!this.errorMessages.includes(`File Upload Limit: Maximum of ${this.maxFileCount} files.`)) {
        this.errorMessages.push(`File Upload Limit: Maximum of ${this.maxFileCount} files.`);
      }
      this.disableUploadError = true;
    }

    for (let i = 0; i < selectedFilesArray.length; i++) {
      const file = selectedFilesArray[i];
      for (let j = 0; j < this.fileInfos.length; j++) {
        if (this.fileInfos[j].name === file.name) {
          if (!this.errorMessages.includes('File was already uploaded.')) {
            this.errorMessages.push(
              'File was already uploaded.'
            );
          }
          this.disableUploadError = true;
        }
      }
    }

    if (this.errorMessages.length > 0) {
      this.message = this.errorMessages.join('. ');
      selectedFilesArray = null;
    }
  }

  async uploadEachFile(): Promise<void> {
    if (!this.selectedFiles || this.disableUploadError) {
      return;
    }
   
    this.fileSelected100MB = false;
    this.message = '';
    this.errorSubmitMsg = undefined;
    if (this.selectedFiles.length > this.maxFileCount) {
      this.selectedFiles = null;
      this.message = `File Upload Limit: Maximum of ${this.maxFileCount} files.`;
      return;
    }
 
    const uploaded = this.fileInfos.length;
    const newUploaded = this.selectedFiles.length + uploaded;
 
    if (newUploaded > this.maxFileCount) {
      this.message = `File Upload Limit: Maximum of ${this.maxFileCount} files.`;
      return;
    }

    console.log('final files to upload', this.selectedFiles);
    this.totlaFilesUploaded = this.selectedFiles.length;

    if (this.failedFiles.length > 0) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        for (let j = 0; j < this.failedFiles.length; j++) {
          if (this.failedFiles[j] === this.selectedFiles[i].name) {
            this.failedFiles.splice(j, 1);
             //break;
          }
        }
      }  
    }
    this.spinner.hide();
    this.uploadedFully = true;
    this.triggeredAPIs = [];
    this.waitTillUpload = true;
  
  
    for (let i = 0; i < this.selectedFiles.length; i++) {
      if (this.selectedFiles.item(i)) {
        await this.upload(this.selectedFiles.item(i));
        this.scrollToBottom();
      }
    }
    this.checkAllFilesUploaded();
  }
  async checkAllFilesUploaded() {
    try {
      let res = await Promise.allSettled(this.triggeredAPIs);
      this.spinner.hide();
      console.log('try', res);
      this.waitTillUpload = false;
      this.scrollToTop();
      if (this.errorReoccured === this.totlaFilesUploaded && this.errorOccured === this.totlaFilesUploaded) {
        this.fileInfos = [];
        this.uploadedFully = true;
      } else {
          this.uploadedFully = false;
      }
    } catch(errorTest) {
      console.log('error test', errorTest);
      this.spinner.hide();
      this.uploadedFully = false;
      this.waitTillUpload = false;
    }
  }

  changeReUploadLogic(failedFiles: any) {
    console.log('change reipp', failedFiles);
      let smallFiles = [];
      if (failedFiles.size <= this.perUploadMaxSmallFileSizeMB *1024 *1024) {
        this.totalSmallFilesUploaded -= 1;
        smallFiles.push(failedFiles);
      }
   
    console.warn('total small', this.totalSmallFilesUploaded);
    //filter small files (>20MB BUT <=100MB)
    let largeFileTest = [];
    if (failedFiles.size  > this.perUploadMaxSmallFileSizeMB *1024 *1024 &&  failedFiles.size <= this.perUploadMaxLargeSizeMB *1024 *1024) {
      
      largeFileTest.push(failedFiles);
    }
    const largeFiles = largeFileTest.filter(file => file.size > this.perUploadMaxSmallFileSizeMB *1024 *1024 && file.size <= this.perUploadMaxLargeSizeMB *1024 *1024);
    //calculte the total size of all selected files(mb)

    const totalLargeFileSizeMB = largeFiles.reduce((total, file) => total + file.size / (1024 * 1024), 0);

    const totalSmallFileSizeMB = smallFiles.reduce((total, file) => total + file.size / (1024 * 1024), 0);

    // Step 3: Track the cumulative total size of small and large files
    this.cumulativeSmallFileSizeMB -= totalSmallFileSizeMB; // Adding the size of small files being uploaded to the cumulative total
    this.cumulativeLargeFileSizeMB -= totalLargeFileSizeMB; // Adding the size of large files being uploaded to the cumulative total

  }
 
  async upload(item: File | null): Promise<void> {
    if (!item) {
      return;
    }
 
    this.progress = 0;
    this.currentFile = item;
 
    if (this.fileInfos.some((file) => file.name === this.currentFile.name)) {
      this.message = 'File was already uploaded.';
      this.currentFile = undefined;
      return;
    }
 
   
    if (this.currentFile.size >   this.maxFileSizeMB * 1024 * 1024 ) {
      this.message =
        'Please upload document within the file size limit of ${this.maxFileSizeMB} MB.'
 
      this.currentFile = undefined;
      this.fileSelected100MB = true;
 
      return;
    }
 
   // this.spinner.show(); //please uncomment after testing
    if (this.currentFile) {
     
      let fileContent = await this.uploadService.toBase64(this.currentFile);
      if (this.currentFile) {
        this.currentFile.fileContent = fileContent;
      
      let sourceTriggered = this.uploadService.upload(this.currentFile);
      this.triggeredAPIs.push(sourceTriggered);
      this.fileInfos.push(this.currentFile);
   
      sourceTriggered.then(
             (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            console.log('event 2', event)
            //this.currentFile.progress = Math.round((100 * event.loaded) / event.total);
            if (this.progress && this.progress > 0) {
             // this.spinner.show(); 
            } else {
             // this.spinner.hide();
            }
           
          } else if (event instanceof HttpResponse) {
            this.uploadedFiles.push(event.body);
            
           // console.log(this.fileInfos);
            this.currentFile.progress = 100;
            this.errorOccured = this.errorOccured + 1;
          }
          if (this.progress === 100 || this.progress === 0) {
           //  this.spinner.hide();  
           }
           if (this.progress === 0) {
            
           }
           if (this.errorOccured === this.fileInfos.length) {
            //  this.fileInfos = [];
           //  this.spinner.hide(); //uncomment after sometime
            }
  
        },
   
        (err: any) => {
          console.log('err', err);
          // this.spinner.hide();
          // this.progress = 0;
          this.errorSubmitMsg =
         'The system is currently unavailable. Please try again later.';
  
         if (err && err.currenObj) {
              this.failedFiles.push(err.currenObj.name);
              this.changeReUploadLogic(err.currenObj);          
      }
         console.log('stpe 1', this.fileInfos);
         console.log('step 2', this.currentFile);
         this.errorOccured = this.errorOccured + 1;
         this.errorReoccured = this.errorReoccured + 1;
        // if(this.currentFile) {
          for (let i = 0; i < this.fileInfos.length; i++) {
            if (this.fileInfos[i].name === err.currenObj.name) {
              this.fileInfos.splice(i, 1);
               //break;
            }
          }  
        // }
         if (this.errorOccured === this.fileInfos.length) {
          //this.fileInfos = [];
          setTimeout(() => {
           
            this.spinner.hide();
           }, 2000)
         }
         if (this.errorReoccured === this.totlaFilesUploaded && this.errorOccured === this.totlaFilesUploaded) {
          this.fileInfos = [];
          setTimeout(() => {
            //this.uploadedFully = true;
          }, 4000)
          } else {
            setTimeout(() => {
             // this.uploadedFully = false;
            }, 4000)
          }
          this.currentFile = undefined;
          this.fileSelected100MB = true;
          this.waitTillUpload = false;
        },
      );
    }
    }  
  }
  scrollToBottom(): void {
    setTimeout(() => {
   window.scrollTo({
    top: document.body.scrollHeight, 
    behavior:'smooth', 
    
   })
    },100);
  }

  scrollToTop(): void {
    // setTimeout(() => {
   window.scrollTo({
    top: 0, 
    behavior:'smooth', 
    
   })
    }

  confirmation(): void {
    const modalRef = this.modalService.open(ConfirmationBoxComponent);
    (<ConfirmationBoxComponent>modalRef.componentInstance).dataToTakeAsInput =
      'Are you sure you want to submit file(s) to NYS Dept of Labor?';
 
    modalRef.result.then((result) => {
      if (result) {
        this.spinner.show();
        this.uploadService.submitData(this.uploadedFiles).subscribe(
          (res: any) => {
            this.spinner.hide();
 
            if (res?.response?.opOutput === 'OPFAILED') {
              this.errorSubmitMsg = res.response.respErrors.errors[0].message;
            } else if (res?.response?.opOutput === 'OPSUCCESS') {
              window.localStorage['confirmationNumber'] =
                res.response.confimationNumber;
 
              this.router.navigate(['docintake/Confirmation']);
            } else {
              this.errorSubmitMsg =
                'The system is currently unavailable. Please try again later.';
            }
          },
 
          (err) => {
            this.spinner.hide();
            this.errorSubmitMsg =
              'The system is currently unavailable. Please try again later.';
          }
        );
      }
    });
  }
 
  removeFile(file: any): void {
    const modalRef = this.modalService.open(ConfirmationBoxComponent);
 
    (<ConfirmationBoxComponent>(
      modalRef.componentInstance
    )).fileToDelete = `Are you sure you want to delete ${file.name}?`;
 
    modalRef.result.then((result) => {
      if (result) {
        this.uploadService.deleteFile(file);
        this.fileInfos = this.fileInfos.filter((f) => f.name !== file.name);
        this.uploadedFiles = this.uploadedFiles.filter(
          (f) => f.orginalFileName !== file.name
        );
      }
    });
  }
 
  onReset(): void {
    this.router.navigate(['docintake/eAudit']);
  }
}