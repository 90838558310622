import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import  { FileUploadService } from '../services/file-upload.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EAuditLandingPageService } from '../services/eAudit-landing-page.service';
import {Location} from "@angular/common";
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { catchError, of, tap } from 'rxjs';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
  currentOrderNumber: any;
  uploadedFiles?:any;
  errorSubmitMsg: any;
  modalService: any;
  confimationNumber: any;
  iclickedBack: boolean = false;
  reCAPTCHAVisible: boolean = false;
  reCAPTCHAToken: string = '';
  recaptchaError: boolean;
  captchaToken:string= '';
  
  constructor(public router: Router, private recaptchaV3Service: ReCaptchaV3Service, public uploadService: FileUploadService, 
    private spinner: NgxSpinnerService, public eAuditService: EAuditLandingPageService, private location: Location ) {
   
    this.confimationNumber  = window.sessionStorage['confirmationNumber'];
    console.log('nubbb', this.confimationNumber);
    
    (<any>window).addEventListener('popstate', (event: any) => {
    }, false); 
    router.events
    .subscribe((event: NavigationStart) => {
      console.log('even', event);
      if (event.navigationTrigger === 'popstate' && !this.iclickedBack) {
        // this.router.navigate(['docintake/eAudit'])
      }
    });
  }

 
ngOnInit(): void {
  this.generateRecaptchaToken();
}
// Generate reCAPTCHA token
generateRecaptchaToken() {
  this.recaptchaV3Service.execute('importantAction').pipe(
    tap((token) => {
      this.reCAPTCHAToken = token;
      this.recaptchaError = false;
    }),
    catchError((error) => {
      console.error('reCAPTCHA error:', error);
      this.handleRecaptchaError(error);
      return of(null);
    })
  ).subscribe();
 }
 handleRecaptchaError(error: any) {
  console.error('reCAPTCHA error:', error);
    this.errorSubmitMsg = "The system is currently unavailable. Please try again later.";
     this.recaptchaError = true;

 }

  goBack() {
    this.iclickedBack = true;
    window.history.go(-1);
  }

  }