import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-zip-unzipinfo',
  templateUrl: './zip-unzipinfo.component.html',
  styleUrls: ['./zip-unzipinfo.component.scss']
})
export class ZipUnzipinfoComponent {
  @Input() dataToTakeAsInput: any;
  @Input() fileToDelete:any;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  confirm() {
   
    this.activeModal.close(true);
  }
  cancel() {
   
    this.activeModal.close(false);
  }
}
