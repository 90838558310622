<div class="modal-header" *ngIf="updateDetails">
    <h4 class="modal-title">Confirmation</h4>
    </div>
    <div class="modal-body" *ngIf="!updateDetails">
        <div class = "warning-message">
        <!-- <div class="alert alert-warning d-flex align-items-center" role="alert"> -->
    <i class="bi bi-exclamation-triangle warning-icon"></i> 
    <span class = "warning-text" >{{ message }}</span>
</div>
        </div>
   
    <div class="modal-body" *ngIf="updateDetails">
        <p>{{ message }}</p>
        </div>
    <div class="modal-footer">
    <button class="btn btn-primary" (click)="confirm()">Yes</button>
    <button class="btn btn-primary" (click)="cancel()">No</button>
    </div>