import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AddressPopupComponent } from '../address-popup/address-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadService } from '../services/file-upload.service';

@Component({
  selector: 'app-employer-form',
  templateUrl: './employerform.component.html',
  styleUrls: ['./employerform.component.scss'],
})
export class EmployerformComponent implements OnInit, OnDestroy {
  employerForm: FormGroup;
  showRepresentativeFields: boolean = false;
  isStateDropdown = true;
  isUSAZip: boolean = true;
  useExistingAddress = true;
  stateList: string[] = [];
  countryList: string[] = ['United States', 'Canada', 'Other'];
  storedErNumber: string | null = null;
  storedCaseId: string | null = null;

  //subsequent submission flags

  isSubsequentSubmission: boolean = false;
  isFormDisabled: boolean = false;

  gotEmployeeDetails: boolean = true;

  isEditingAddress: boolean = false; // Controls address fields only
  hasStoredAddress: boolean = false; // Check if an address exists
  originalAddress: any = {}; // Store original address for comparison
  popupDismissed: boolean = false;
  hasAddressChanged: boolean = false;
  originalSubsequentEmployerValues: any;
  hasEmployerChanged: any;
  resetSubsequentFormData: any;
  serverOriginalSubsequentEmployer: any;
  missingFields: any[];
  showBanner: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    public fileuploadservice: FileUploadService

  ) {
    this.employerForm = this.fb.group({
      principalOfficerName: ['', Validators.required],
      principalEmail: ['', [Validators.required, Validators.email, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$")]],
      principalPhone: ['', [Validators.required]],
      countryCode: ['+1', [Validators.pattern('^\\+?[0-9]{1,3}$')]],
      title: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern('^[0-9]{5}$'), Validators.maxLength(5)]],
      zipExt: ['', [Validators.pattern('^[0-9]{4}$'), Validators.maxLength(4)]],
      country: ['', Validators.required],
      notes: ['', Validators.maxLength(500)],
      addRepresentative: ['No'],
      repName: [''],
      repEmail: ['', [Validators.required, Validators.email]],
      repPhone: ['', [Validators.required]],
      repCountryCode: ['', [Validators.pattern('^\\+?[0-9]{1,3}$')]],
      //subsequent
      editEmployer: ['No'],
      editEmployerAddress: ['No']
    });
    this.serverOriginalSubsequentEmployer = {};

  }

  setDefaultEmployerData(data: any) {
            if (data && data.addRepresentative === 'Yes') {
              this.showRepresentativeFields = true;
            }
            console.log('form data', data)
            this.employerForm.patchValue(data);
            console.log('test meeeee')
            this.toggleAddressFields(data.editEmployerAddress);
            this.hasStoredAddress = true;
            if (data && data.hasOwnProperty('editEmployer') && sessionStorage.getItem('docUpload') === 'true') {
              this.toggleEditFields(data.editEmployer)
            }
            if (this.employerForm.get('country').value !== 'United States') {
              // this.employerForm.get('zip')?.reset();
              this.employerForm.get('zip')?.clearValidators();
            }
            
            setTimeout(() => {
             let  selectedValue = this.employerForm.get('editEmployerAddress')?.value || 'No';
             this.employerForm.get('editEmployerAddress')?.setValue(selectedValue);
            }, 200);
  }

  ngOnInit() {
    this.trackEmployer();
    this.initializeStates();
    this.loadOriginalSubsequentEmployerValues();

    const storedAddress = sessionStorage.getItem('addressJson');
    if (storedAddress) {
      try {
        const addressObj = JSON.parse(storedAddress);

        if (!this.stateList || !this.countryList || this.stateList.length == 0 || this.countryList.length === 0) {
          return;
        }

        let oldStateValue = this.stateList.find((state) => state?.toUpperCase() === addressObj?.state?.toUpperCase());
        let stateValue = oldStateValue ? oldStateValue : addressObj.state
        let countryValue = this.countryList.find((country) => country?.toUpperCase() === addressObj?.country?.toUpperCase()) || '';

        this.fileuploadservice.currentFormData.subscribe(data => {
          if (data) {
            this.setDefaultEmployerData(data)
          } else {
            // console.log('Mapped State:', stateValue);
            // console.log('Mapped Country:', countryValue);
             this.hasStoredAddress = true;
             this.toggleAddressFields("No");
          }
          this.employerForm.patchValue({
            address1: addressObj.addrline1 || '',
            address2: addressObj.addrline2 || '',
            city: addressObj.city || '',
            state: stateValue || '', // Set dropdown value
            country: countryValue || '', // Set dropdown value
            zip: addressObj.zip,
            zipExt: addressObj.zipextn,
          });
          if (this.employerForm.get('country').value !== 'United States') {
            // this.employerForm.get('zip')?.reset();
            this.employerForm.get('zip')?.clearValidators();
          }
        });

       
      } catch (error) {
        console.error('Error fetching employer details:', error);
      }
    } else {
      this.hasStoredAddress = false;
    }
    this.fileuploadservice.currentFormData.subscribe(data => {
      if (data) {
        this.setDefaultEmployerData(data);
      } else {
        this.checkMissingAddressFields(JSON.parse(storedAddress));
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url.includes('employerForm')) {
      }
    });
    this.resetSubsequentFormData = this.employerForm.getRawValue();
    window.sessionStorage['ilogged'] = 'ilogged';
  }

  checkMissingAddressFields(addressObj: any) {
    const requiredFields = ['addrline1', 'city', 'state', 'country', 'zip'];
    const missingFields = requiredFields.filter(field => !addressObj[field] || addressObj[field].trim() === '');
   const addressControls = [ "address1", "address2", "city", "state", "country", "zip", "zipExt"];

    if (missingFields.length === 0) {
      this.showBanner = false; // Address is complete, hide banner
     this.hasStoredAddress = true; // Allow Yes/No 
     // this.isEditingAddress = false;
    // addressControls.forEach(field => this.employerForm.get(field)?.disable());
     
    } else {
      this.showBanner = true; // Address is incomplete, show banner
      this.hasStoredAddress = false;
     //this.isEditingAddress = true;
     addressControls.forEach(field => this.employerForm.get(field)?.enable());

    }
    console.log("show banner:", this.showBanner);
    console.log("isEditingAddress:", this.isEditingAddress );
    console.log("Address object", addressObj);
    console.log("missing fields:",  missingFields);

    if (this.isSubsequentSubmission &&  this.employerForm.get('editEmployer').value === 'No') {
      this.toggleAddressFields('No');
      
    }

  }
loadOriginalSubsequentEmployerValues(){
  const addressJson = sessionStorage.getItem ('addressJson') ? JSON.parse(sessionStorage.getItem ('addressJson')) : {};
  const principalOfficerDetails = sessionStorage.getItem ('principalOfficerDetails') ? JSON.parse(sessionStorage.getItem ('principalOfficerDetails')) : {};
  const repDetails = sessionStorage.getItem ('repDetails') ? JSON.parse(sessionStorage.getItem ('repDetails')) : {};

this.originalSubsequentEmployerValues =  {
 ...addressJson, ...principalOfficerDetails, ...repDetails

  };
  console.log ("original eployer values laoded", this.originalSubsequentEmployerValues)
  const originalSubsequentEmployer = this.originalSubsequentEmployerValues;

  this.serverOriginalSubsequentEmployer = {
    // addresdesc: originalSubsequentEmployer.addresdesc, 
    address1: originalSubsequentEmployer.addrline1,
    address2: originalSubsequentEmployer.addrline2,
    // addrtype: originalSubsequentEmployer.addrtype,
    city: originalSubsequentEmployer.city,
    country: originalSubsequentEmployer.country,
    state: originalSubsequentEmployer.state,
    zip: originalSubsequentEmployer.zip,
    zipExt:originalSubsequentEmployer.zipextn ? originalSubsequentEmployer.zipextn : '',
    principalEmail: originalSubsequentEmployer.principalEmailAddress,
    principalOfficerName: originalSubsequentEmployer.principalOfficerName,
    principalPhone: originalSubsequentEmployer.principalPhoneNumber,
    title: originalSubsequentEmployer.principalOfficerTitle,
    repEmail: originalSubsequentEmployer.repEmailAddress,
    repName: originalSubsequentEmployer.repOfficerName,
    repPhone: originalSubsequentEmployer.repPhoneNumber
  }
}
 
  trackEmployer() {
    // Retrieve the docUpload flag from session storage
    const docUpload = sessionStorage.getItem('docUpload') === 'true';
    if (docUpload) {
      console.log("Subsequent employer form detected");
      this.isSubsequentSubmission = true; // Show radio buttons for subsequent form
      this.isFormDisabled = false;
      this.employerForm.get('editEmployer')?.setValue('No'); // Default to "No"
      // Disable all fields except Notes
      Object.keys(this.employerForm.controls).forEach((field) => {
        if (field !== 'notes' && field !== 'editEmployer') {
          this.employerForm.get(field)?.disable();
        }
      });

      // load principal officer and rep details fields
    this.loadPrincipalAndRepDetails();
    } else {
      this.isSubsequentSubmission = false; //  No radio buttons for initial form
      this.employerForm.enable(); //  Ensure all fields are enabled initially
    }
  }

  loadPrincipalAndRepDetails() {
    const principalOfficerDetails = JSON.parse(sessionStorage.getItem('principalOfficerDetails') || '{}');
    const repDetails = JSON.parse(sessionStorage.getItem('repDetails') || '{}');

    if (Object.keys(principalOfficerDetails).length) {
      const principalPhone = splitPhoneNumber(principalOfficerDetails.principalPhoneNumber || '');

      this.employerForm.patchValue({
        principalOfficerName : principalOfficerDetails.principalOfficerName,
        title: principalOfficerDetails.principalOfficerTitle,
        principalEmail: principalOfficerDetails.principalEmailAddress,
        principalPhone: principalPhone.phoneNumber,
        countryCode :principalPhone.countryCode
      
      });
      console.log("formatted phone:", principalPhone)
    } else {
      console.error("No principal office details found in session storage")
    }

    const isEmpty = Object.values(repDetails).every(x => x === null || x == '');
    if (Object.keys(repDetails).length && !isEmpty) {
      const repPhone = splitPhoneNumber(repDetails.repPhoneNumber || '');
      this.showRepresentativeFields = true;
      this.employerForm.get('addRepresentative')?.setValue('Yes');
      this.employerForm.patchValue({
        repName : repDetails.repOfficerName,
        repEmail: repDetails.repEmailAddress,
        repPhone: repPhone.phoneNumber,
        repCountryCode: repPhone.countryCode,
      });
    } else {
      console.error("No rep details found in session storage")
    }
  }

  restoreFormState() {
    setTimeout(() => {
     let  selectedValue = this.employerForm.get('editEmployerAddress')?.value || 'No';
     this.employerForm.get('editEmployerAddress')?.setValue(selectedValue);
    }, 200);
  }

  // Toggle address fields based on user selection
  toggleAddressFields(value: string) {
    console.log('on load value', value)
    this.employerForm.get('editEmployerAddress')?.setValue(value);
    const addressControls = ["address1", "address2", "city", "state", "country", "zip", "zipExt"];
    if (value === 'Yes') {
      this.isEditingAddress = false;
      addressControls.forEach(field => this.employerForm.get(field)?.enable());
    } else {
      this.isEditingAddress = true;
      addressControls.forEach(field => this.employerForm.get(field)?.disable());
    }
  }

  //enable/disable fields based on selection
  toggleEditFields(value: string): void {
    this.employerForm.get('editEmployer')?.setValue(value);
    if (value === 'Yes') {
      this.isFormDisabled = false;
      this.employerForm.enable();
    } else {
      this.isFormDisabled = true;
      Object.keys(this.employerForm.controls).forEach((field) => {
        if (field !== 'notes' && field !== 'editEmployer') {
          this.employerForm.get(field)?.disable();
        }
        //if address radio is yes , reset it no when editemployer radio no
        if (this.employerForm.get('editEmployerAddress')?.value === 'Yes') {
          this.employerForm.get('editEmployerAddress')?.setValue('No');
        }

      });
    }
    this.employerForm.get('editEmployer')?.enable();
    let editEmployerAddress = this.employerForm.get('editEmployerAddress').value;
    this.toggleAddressFields(editEmployerAddress)

  }

  toggleEditChangeFields(value: string): void {
    this.employerForm.get('editEmployer')?.setValue(value);
    this.isFormDisabled = true;
    if (value === 'Yes') {
      this.isFormDisabled = false;
      this.employerForm.enable();
      if (this.showBanner) {
        this.employerForm.get('editEmployerAddress')?.setValue(value);
      }

    } else {
      let getFormData = this.employerForm.getRawValue();
      delete getFormData.editEmployer;
      delete this.resetSubsequentFormData.editEmployer;
      // getFormData.addresdesc  = this.serverOriginalSubsequentEmployer.addresdesc;
      // getFormData.addrtype = this.serverOriginalSubsequentEmployer.addrtype;
      getFormData.principalPhone = getFormData.countryCode + getFormData.principalPhone;
      getFormData.repPhone = getFormData.repCountryCode + getFormData.repPhone;
      Object.keys(this.employerForm.controls).forEach((field) => {
        if (field !== 'notes' && field !== 'editEmployer') {
          this.employerForm.get(field)?.disable();
        }
        //if address radio is yes , reset it no when editemployer radio no
        if (this.employerForm.get('editEmployerAddress')?.value === 'Yes') {
          this.employerForm.get('editEmployerAddress')?.setValue('No');
        }
      });
       console.log('1st data', getFormData);
       console.log('2nd for', this.serverOriginalSubsequentEmployer);
       console.log('3rd balue', this.areAddressesEqual(getFormData, this.serverOriginalSubsequentEmployer))
      
      if (!this.areAddressesEqual(getFormData, this.serverOriginalSubsequentEmployer)) {

        const modalRef = this.modalService.open(AddressPopupComponent, {
          centered: true,
          backdrop: 'static',
          keyboard: false,
        });
    
        modalRef.componentInstance.message = "Your updates will not be saved. Do you still want to proceed?";
        modalRef.componentInstance.updateDetails = false;
        modalRef.result.then((confirmed: boolean) => {
          if (confirmed) {
            console.log('1st entered', this.resetSubsequentFormData);
         const currentNotes = this.employerForm.get('notes')?.value;
         let principalPhoneNumber = splitPhoneNumber(this.serverOriginalSubsequentEmployer.principalPhone || '')
         let repPhone = splitPhoneNumber(this.serverOriginalSubsequentEmployer.repPhone || '');
         let patchinhOldData = JSON.parse(JSON.stringify(this.serverOriginalSubsequentEmployer));
         patchinhOldData.principalPhone = principalPhoneNumber.phoneNumber;
         patchinhOldData.repPhone = repPhone.phoneNumber;
         patchinhOldData.countryCode = principalPhoneNumber.countryCode;
         patchinhOldData.repCountryCode = repPhone.countryCode;
        
          this.employerForm.patchValue({ ...patchinhOldData, notes: currentNotes});
           console.log('employe raw val', this.employerForm.getRawValue())

           const repDetails = JSON.parse(sessionStorage.getItem('repDetails') || '{}');

          
           const isEmpty = Object.values(repDetails).every(x => x === null || x == '');
           if (Object.keys(repDetails).length && !isEmpty) {
             const repPhone = splitPhoneNumber(repDetails.repPhoneNumber || '');
             this.showRepresentativeFields = true;
             this.employerForm.get('addRepresentative')?.setValue('Yes');
             
           } else {
             console.error("No rep details found in session storage")
           }


          } else {
           this.isFormDisabled = false;
          this.employerForm.enable();
          this.employerForm.get('editEmployer')?.setValue('Yes');
        
          }
        }).catch(() => { });
      }
    }
    this.employerForm.get('editEmployer')?.enable();
    let editEmployerAddress = this.employerForm.get('editEmployerAddress').value;
    this.toggleAddressFields(editEmployerAddress)

  }

  private initializeStates() {
    this.stateList = [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'Washington D.C',
    ];
  }

  private previousState: String = '';
  private isManualCountryCode: Boolean = false;

  onCountryChange(event: any) {
    this.employerForm.get('zip')?.reset();
    this.employerForm.get('zipExt')?.reset();
    this.employerForm.get('state').reset();
    this.employerForm.get('zip').markAsUntouched();
    this.employerForm.get('zip')?.clearValidators();
    const selectedCountry = event.target.value;
    
    if (selectedCountry === 'United States') {
      this.isStateDropdown = true;
      this.initializeStates();
      this.employerForm.patchValue({ state: this.previousState });
     // this.employerForm.get('state').setValidators([Validators.required]);
    } else {
      this.isStateDropdown = false;
      this.previousState = '';
      
      this.employerForm.patchValue({ state: '' });
      
    }
    this.employerForm.get('state').markAsUntouched()
    this.employerForm.get('state')?.clearValidators();
    this.employerForm.get('state').setValidators([Validators.required]);
   // this.employerForm.get('state')?.updateValueAndValidity();
    // this.employerForm.get('state')?.markAsTouched();

    if (selectedCountry === 'United States') {
      this.isUSAZip = true;
      this.employerForm.get('zip')?.setValidators([Validators.required, Validators.pattern('^[0-9]{5}$'), Validators.maxLength(5)]);
      this.employerForm.get('zipExt')?.setValidators([Validators.pattern('^[0-9]{4}$'), Validators.maxLength(4)]);
    } else {
      this.isUSAZip = false;
      this.employerForm.get('zip')?.setValidators([Validators.pattern('^[A-Za-z0-9]{1,20}$'), Validators.maxLength(20)

      ]);
      this.employerForm.get('zipExt')?.setValidators([]);
    }
   // this.employerForm.get('zip')?.updateValueAndValidity();
    this.employerForm.get('zipExt')?.updateValueAndValidity();
  }
  toggleRepresentativeFields(value: string): void {
    this.showRepresentativeFields = value === 'Yes';
    ['repName', 'repEmail', 'repPhone', 'repCountryCode'].forEach(field => {
      this.employerForm.get(field)?.setValue('');
      this.employerForm.get(field)?.clearValidators();
      this.employerForm.get(field)?.updateValueAndValidity();
    });
    if (this.showRepresentativeFields) {
      this.employerForm.get('repName')?.setValidators(Validators.required);
      this.employerForm.get('repEmail')?.setValidators([Validators.required, Validators.email, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$")]);
      this.employerForm.get('repPhone')?.setValidators(Validators.required);
      this.employerForm.get('repCountryCode')?.setValue('+1');
      this.employerForm.get('repCountryCode')?.setValidators([Validators.pattern('^\\+?[0-9]{1,3}$')]);
    }
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    const char = event.key;
    if (!/^\d$/.test(char)) {
      event.preventDefault();
    }
  }

  validateCountryCode(event: KeyboardEvent) {
    const char = String.fromCharCode(event.charCode);
    if (!/^\+?[0-9]*$/.test(char)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  checkAddressChanges(): boolean {
    //retrieve the updated from the employer form 
    const updatedAddress = {
      address1: this.employerForm.get('address1')?.value ? this.employerForm.get('address1')?.value.toLowerCase() : '',
      address2: this.employerForm.get('address2')?.value ? this.employerForm.get('address2')?.value.toLowerCase() : '',
      city: this.employerForm.get('city')?.value ? this.employerForm.get('city')?.value.toLowerCase() : '',
      state: this.employerForm.get('state')?.value ? this.employerForm.get('state')?.value.toLowerCase() : '',
      country: this.employerForm.get('country')?.value ? this.employerForm.get('country')?.value.toLowerCase() : '',
      zip: this.employerForm.get('zip')?.value ? this.employerForm.get('zip')?.value.toLowerCase() : '',
      zipExt: this.employerForm.get('zipExt')?.value ? this.employerForm.get('zipExt')?.value.toLowerCase() : '',

    };
      console.log("updatedaddress", updatedAddress);
      //retrieve the original address from session storage
    let originalstoredAddress = JSON.parse(sessionStorage.getItem('addressJson'));

     console.log('original text', originalstoredAddress)
    let originalAddress = {
      address1: originalstoredAddress.addrline1 ? originalstoredAddress.addrline1.toLowerCase() : '',
      address2: originalstoredAddress.addrline2 ? originalstoredAddress.addrline2.toLowerCase() : '',
      city: originalstoredAddress.city ? originalstoredAddress.city.toLowerCase() : '',
      state: originalstoredAddress.state ? originalstoredAddress.state.toLowerCase() : '', // Set dropdown value
      country: originalstoredAddress.country ? originalstoredAddress.country.toLowerCase() : '', // Set dropdown value
      zip: originalstoredAddress.zip ? originalstoredAddress.zip.toLowerCase() : '',
      zipExt: originalstoredAddress.zipextn ? originalstoredAddress.zipextn.toLowerCase() : '',
    }
     console.log('check me', JSON.stringify(updatedAddress) === JSON.stringify(originalAddress))
    return JSON.stringify(updatedAddress) === JSON.stringify(originalAddress);
  }


  checkEmployerChanges(): boolean {
     const updatedSubsequentEmployer = JSON.parse(sessionStorage.getItem('employerInfo'))
        
        delete updatedSubsequentEmployer.editEmployer;
        delete updatedSubsequentEmployer.editEmployerAddress;
        delete updatedSubsequentEmployer.notes;
        delete updatedSubsequentEmployer.addRepresentative;
        
        
           console.log("Comparing employer details...",updatedSubsequentEmployer );
           console.log("Comparing server employer details...", this.serverOriginalSubsequentEmployer);
        return !(this.areAddressesEqual(updatedSubsequentEmployer, this.serverOriginalSubsequentEmployer))
}

 areAddressesEqual(addr1: any, addr2: any): boolean {
  const keys = [ "address1", "address2", "city", "state", "country", "zip", "zipExt", "principalEmail", "principalOfficerName", "principalPhone", "title", "repEmail", "repName", "repPhone" ];
  for (const key of keys) {
    let value1 = addr1[key] ?? ""; // Convert null/undefined to empty string
    let value2 = addr2[key] ?? "";
    if (typeof value1 === "string") value1 = value1.trim().toLowerCase();
    if (typeof value2 === "string") value2 = value2.trim().toLowerCase();
    if (value1 !== value2) {
      console.log(`Mismatch found in "${key}": "${value1}" vs "${value2}"`);
      return false;
    }
  }
  return true;
}  

  showAddressConfirmPopup() {
    // console.log("opening popup...")
    const modalRef = this.modalService.open(AddressPopupComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.message = "No update in address found. Do you still want to change the address?";
    modalRef.result.then((confirmed: boolean) => {
      if (confirmed) {
        this.employerForm.get('editEmployerAddress')?.setValue('Yes');
        this.toggleAddressFields("Yes");
      } else {
        this.employerForm.get('editEmployerAddress')?.setValue('No');
        this.toggleAddressFields("No");
      }
    }).catch(() => { });

  }

  showSubsequentFormConfirmPopup() {
    console.log("opening popup...")

    const modalRef = this.modalService.open(AddressPopupComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.message = "No updates found. Do you still want to update?";
    modalRef.result.then((confirmed: boolean) => {
      if (confirmed) {
        this.employerForm.get('editEmployer')?.setValue('Yes') ;
        this.toggleEditFields("Yes");
        
      } else {

        this.employerForm.get('editEmployer')?.setValue('No');
        this.toggleEditFields("No");
      }
    }).catch(() => { });
  }

  validateDefaultRules() {
    console.log("Form Submitted Successfully!", this.employerForm.getRawValue());
    const countryCode = this.employerForm.get('countryCode')?.value || '';
    const principalPhone = this.employerForm.get('principalPhone')?.value || '';

    const combinedPhone = `${countryCode}${principalPhone}`;
    //console.log("Combined Phone:", combinedPhone);

    const repCountryCode = this.employerForm.get('repCountryCode')?.value || '';
    const repPhone = this.employerForm.get('repPhone')?.value || '';
    

    const combinedRepPhone = `${repCountryCode}${repPhone}`;
    //console.log("Combined repPhone:", combinedRepPhone);

    const storedAddress = sessionStorage.getItem('addressJson');
    const addressObj = JSON.parse(storedAddress);

    let originalAddress = sessionStorage.getItem('addressJson');

    let employerData = {
      ...this.employerForm.getRawValue(),
      principalPhone: combinedPhone,
      repPhone: combinedRepPhone,
      addrtype: originalAddress === null ? '' : (addressObj && addressObj.hasOwnProperty('addrtype') ? addressObj.addrtype : '') ,
      addresdesc: originalAddress === null ? '' :(addressObj && addressObj.hasOwnProperty('addrdesc') ? addressObj.addresdesc : ''),
    };
    if ('countryCode' in employerData) {
      delete employerData.countryCode;
    }
    if ('repCountryCode' in employerData) {
      delete employerData.repCountryCode;
    }

    sessionStorage.setItem('employerInfo', JSON.stringify(employerData));
    console.log('employerform', employerData);
  }

  onSubmit(): void {
    if (this.employerForm.get('addRepresentative')?.value === 'No') {
      ['repName', 'repEmail', 'repPhone'].forEach(field => {
        this.employerForm.get(field)?.clearValidators();
        this.employerForm.get(field)?.setValue('');
        this.employerForm.get(field)?.updateValueAndValidity();
      });
    }
    Object.keys(this.employerForm.controls).forEach(field => {
      const control = this.employerForm.get(field);
      if (control) {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
    console.log("next button clicked");
   // console.log('emplyee for', this.employerForm);
    Object.keys(this.employerForm.controls).forEach(key => {
      // console.log(this.employerForm.get(key).errors)
    })
    if (this.employerForm.invalid) {
      return;
    }

    let originalAddress = sessionStorage.getItem('addressJson');

      console.log('originaladres ******', originalAddress);
      console.log(typeof originalAddress);

    if (originalAddress) {

      if (this.employerForm.get('editEmployerAddress')?.value === 'Yes' && !this.isSubsequentSubmission) {
        this.hasAddressChanged = this.checkAddressChanges();
        // console.log('has address changed', this.hasAddressChanged)
        if (this.hasAddressChanged) {
          // console.log('no address changes detected, showing apopup')
          this.showAddressConfirmPopup();
          return;
        }
        this.validateDefaultRules();
      }
    }
        if (this.employerForm.get('editEmployer')?.value === 'Yes' && this.isSubsequentSubmission) {
          this.validateDefaultRules();
          this.hasEmployerChanged = this.checkEmployerChanges();
          // console.log('has employer changed', this.hasEmployerChanged)
          if (!this.hasEmployerChanged ) {
            // console.log('no employer changes detected, showing apopup')
            this.showSubsequentFormConfirmPopup();
            return;
          }
    }
    
    this.validateDefaultRules();
    this.router.navigate(['docintake/eAuditfileUpload']);

  }
  

  onReset(): void {
   //  this.router.navigate(['docintake/eAudit'], {skipLocationChange:false});
   window.history.go(-1);
  }

  ngOnDestroy(): void {
    this.fileuploadservice.updateEmployeerFormData(this.employerForm.getRawValue())
  }
}

function splitPhoneNumber(fullPhoneNumber: string):  {countryCode : string, phoneNumber : string } {
  if (!fullPhoneNumber || fullPhoneNumber.length <= 10 ) {
    return {countryCode: '', phoneNumber: fullPhoneNumber};
  }

  let phoneNumber = fullPhoneNumber.slice(-10);
  let countryCode = fullPhoneNumber.slice(0, fullPhoneNumber.length -10);
 
    return {countryCode, phoneNumber };
}
  
