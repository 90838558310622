<!-- Removing the accessibility link to main content for now, causing routing issues -->
<!-- Reasoning: skipping to main content only skips two buttons, shouldn't be a big LOE for user -->
   <header class="topbar">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center">
        <a class="navbar-brand"
           href="https://dol.ny.gov"
           target="_blank"
           rel="noopener"
           tabindex="-1">
          <img src="./assets/nys-logo.png"
               class="nys-logo"
               alt="NYS Logo" />
        </a>
        <div class="header text-white"
             style="opacity: 0.9;">
          <h1 class="mb-0 brand-header">
            Department of Labor</h1>
            <div class="sub-text">Document Submission Portal</div>
        </div>
      </div>
    </div>
  </header>
  <main role="main"
        id="main">
    <router-outlet></router-outlet>
  </main>
