import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable, catchError, map, throwError, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private baseUrl = environment.uploadURL;
  private submitDataUrl = environment.submissionUrl;
  public currentIpAddress = '';



  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'X-Frame-Options': 'SAMEORIGIN',
      // 'Content-Security-Policy': "frame-ancestors 'self'",
      'timeout': 36000
    })
  };

  constructor(private http: HttpClient) {
    this.http.get('https://jsonip.com/').subscribe((data: any) => {
      this.currentIpAddress = data.ip;
    });
  }

  toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });


  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }



  upload(file: any): Promise<any> {
    //const fileContent: any = await this.toBase64(file);
    //console.log (fileContent);
    let fileData = {
      browserDetails: this.getBrowserName(),
      guid: "",
      IpAddress: this.currentIpAddress,
      userId: window.localStorage['caseid'],
      fileName: file.name,
      businessEntity: "eaudit",
      fileContent: file.fileContent.split(',')[1],
      
    }
    // const req = new HttpRequest('POST', `${this.baseUrl}/docupload/api/v1/uploadSingleDocument`, fileData,  {
    //   reportProgress: true,
    //   responseType: 'json',
    //   ...this.httpOptions
      
    // });
    return this.http.post(`${this.baseUrl}/docupload/api/v1/uploadSingleDocument`, fileData, {
      reportProgress: true,
      observe: 'events',
      ...this.httpOptions
      
    }).pipe(timeout(36000),  map(response => {
      if (response.type === HttpEventType.UploadProgress) {
        file.progress = Math.round((100 * response.loaded) / response.total);
      }
      return response;
    }),
    // return this.http.request(req).pipe(timeout(36000),  map(response => {
    //   return response;
    // }),
    catchError(error => {
      error.currenObj = file;
      return throwError(error);
    })).toPromise();

  }


  submitData(data: any): Observable<any> {
    let newFileIds = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        newFileIds.push(data[i].newFileName)
      }
    }

    let fileData = {
      userName: 'DOLExternalWebApp', 
      request: {
        domainName: "eaudit",
        caseId: window.localStorage['caseid'],
        ern: window.localStorage['erNumber'],
        browserName: this.getBrowserName(),
        ipAddress: this.currentIpAddress,
        fileIds: newFileIds
      }
    }

    return this.http.post(this.submitDataUrl + '/docprocessor/api/v1/submission/', fileData, this.httpOptions).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }



  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }

  deleteFile(file: any): Observable<any> {
    return this.http.delete(`${this.baseUrl}/files/` + file.name);
  }
}