<div class="container">
  <h3 class="text-center" style="margin-bottom: 20px">Employer Information</h3>
  <form [formGroup]="employerForm">

    <div class="form-group" *ngIf="isSubsequentSubmission">
      <label class="form-label">Has the business owner information, address or representative information changed since
        the last submission?</label>
      <div>
        <input type="radio" id="editYes" class="editEmployer" value="Yes" formControlName="editEmployer"
          (change)="toggleEditChangeFields('Yes') " [checked]="employerForm.get ('editEmployer')?.value ==='Yes'" />
        <label for="editYes"> &nbsp;Yes</label>
        &nbsp;&nbsp;
        <input type="radio" id="editNo" class="editEmployer" value="No" formControlName="editEmployer"
          (change)="toggleEditChangeFields('No') " [checked]="employerForm.get ('editEmployer')?.value ==='No'" />
        <label for="editNo"> &nbsp;No</label>

      </div>
    </div>
    <div>
      <label for="principalOfficerName" class="form-label">Principal/Officer Name <span
          class="text-danger">*</span></label>
      <input type="text" id="principalOfficerName" class="form-control" maxlength="100"
        formControlName="principalOfficerName" placeholder="Enter Principal/Officer Name" autocomplete="off"
        oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z\s]/g, '')"
        [disabled]="isSubsequentSubmission && isFormDisabled" required>


      <div
        *ngIf="employerForm.get('principalOfficerName')?.touched && employerForm.get('principalOfficerName')?.invalid"
        class="alert alert-danger">
        Please enter a name.
      </div>
    </div>
    <div>
      <label for="title" class="form-label">Title <span class="text-danger">*</span></label>
      <input type="text" id="title" class="form-control" formControlName="title" maxlength="100"
        placeholder="Enter Title" oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z\s.]/g, '')"
        autocomplete="off" [disabled]="isSubsequentSubmission && isFormDisabled" required />
      <div *ngIf="employerForm.get('title')?.touched && employerForm.get('title')?.invalid" class="alert alert-danger">
        Please enter a title.
      </div>
    </div>
    <div>
      <label for="principalEmail" class="form-label">Principal Email Address <span class="text-danger">*</span></label>
      <input type="text" id="principalEmail" class="form-control" formControlName="principalEmail" maxlength="100"
        placeholder="name@domain.com" autocomplete="off" [disabled]="isSubsequentSubmission && isFormDisabled"
        required />
      <div *ngIf="employerForm.get('principalEmail')?.touched && employerForm.get('principalEmail')?.invalid"
        class="alert alert-danger">
        Please enter a valid email address.
      </div>
    </div>
    <div class="form-row">
      <div class="form-column">
        <label for="principalPhone" class="form-label">Principal Phone Number <span class="text-danger">*</span></label>
        <div class="input-group">
          <input type="text" id="countryCode" class="form-control country-code" formControlName="countryCode"
            maxlength="3" inputmode="numeric" (keypress)="validateCountryCode($event)" autocomplete="off"
            style="max-width: 60px" />

          <input type="text" id="principalPhone" class="form-control" formControlName="principalPhone"
            placeholder="Enter Phone Number" mask="(000) 000-0000" autocomplete="off"
            [disabled]="isSubsequentSubmission && isFormDisabled" required />

        </div>
        <div *ngIf="employerForm.get('principalPhone')?.touched && employerForm.get('principalPhone')?.invalid"
          class="alert alert-danger">
          Please enter a valid phone number.
        </div>
      </div>
    </div>

    <!-- Banner if no address is found -->
    <div *ngIf="!hasStoredAddress || showBanner" class="alert alert-info" role="alert">
      <i class="bi bi-info-circle-fill" style="
  margin-right: 6px;"></i>
      <b> No address found. Please enter an address to continue.</b>
    </div>

    <div *ngIf="hasStoredAddress">
      <label class="form-label">Do you want to update the address for this case?</label>
      <div>
        <input type="radio" id="editYes" class="editEmployer" value="Yes" formControlName="editEmployerAddress"
          (change)="toggleAddressFields('Yes') " [checked]="employerForm.get ('editEmployerAddress')?.value ==='Yes'" />
        <label for="editYes"> &nbsp;Yes</label>
        &nbsp;&nbsp;
        <input type="radio" id="editNo" class="editEmployer" value="No" formControlName="editEmployerAddress"
          (change)="toggleAddressFields('No') " [checked]="employerForm.get ('editEmployerAddress')?.value ==='No'" />
        <label for="editNo"> &nbsp;No</label>

      </div>
    </div>


    <div class="form-section">
      <h3 class="section-title">Physical Address</h3>
      <div class="compact-group">
        <label for="address1" class="form-label">Address 1 <span class="text-danger">*</span></label>
        <input type="text" id="address1" class="form-control" formControlName="address1" placeholder="Enter Address 1"
          maxlength="100" oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z0-9\s,.\-'/#']/g, '')"
          autocomplete="off" [disabled]="isSubsequentSubmission && isFormDisabled || (!isEditingAddress)" required />
        <div *ngIf="employerForm.get('address1')?.touched && employerForm.get('address1')?.invalid"
          class="alert alert-danger">
          Please enter an address.
        </div>
        <label for="address2" class="form-label">Address 2</label>
        <input type="text" id="address2" class="form-control" formControlName="address2" placeholder="Enter Address 2"
          maxlength="100" oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z0-9\s,.\-'/#']/g, '')"
          autocomplete="off" [disabled]="isSubsequentSubmission && isFormDisabled || !isEditingAddress" />

        <label for="city" class="form-label">City<span class="text-danger">*</span></label>
        <input type="text" id="city" class="form-control" formControlName="city" placeholder="Enter City"
          maxlength="100" oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z\s]/g, '')" autocomplete="off"
          [disabled]="isSubsequentSubmission && isFormDisabled || !isEditingAddress" required />
        <div *ngIf="employerForm.get('city')?.touched && employerForm.get('city')?.invalid" class="alert alert-danger">
          Please enter a valid city.
        </div>

        <div class=form-row>
          <div class="form-label">

            <label for="country">Country <span class="text-danger">*</span></label>
            <select id="country" formControlName="country" class="form-control"
              [disabled]="isSubsequentSubmission && isFormDisabled || !isEditingAddress"
              (change)="onCountryChange($event)">
              <option value="" disabled selected>Select Country</option>
              <option *ngFor="let country of countryList" [value]="country">{{ country }}</option>
              
              
            </select>
            <div *ngIf="employerForm.get('country')?.touched && employerForm.get('country')?.invalid"
                class="alert alert-danger">
                Please select a country.
              </div>
          </div>

          <div class="form-label">

            <label for="state">
              {{employerForm.get('country')?.value === 'United States' ? 'State' : 'State/Province'}} 
              <span class="text-danger">*</span></label>

            <ng-container *ngIf="employerForm.get('country')?.value === 'United States'; else textInput">
              <select id="state" formControlName="state" class="form-control">
                <option value="">Select State</option>
                <option *ngFor="let state of stateList" [value]="state" autocomplete="off" required
                  [disabled]="isSubsequentSubmission && isFormDisabled">{{ state }}</option>
              </select>
            </ng-container>
            <ng-template #textInput>
              <input type="text" id="state" formControlName="state" class="form-control" autocomplete="off" required
                [disabled]="isSubsequentSubmission && isFormDisabled || !isEditingAddress"
                maxlength="100"
                oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z0-9À-ÖØ-öø-ÿ\s\-'.]/g, '')"
                placeholder="Enter State/province">
            </ng-template>

            <!-- <div *ngIf="employerForm.get('state')?.touched && employerForm.get('state')?.invalid"
              class=" alert alert-danger">
              {{ employerForm.get('country')?.value === 'United States' ? 'Please select a state.' : 'Please enter a valid state/province.'}}
            </div>  -->
        
          </div>
        </div>
        <div *ngIf="employerForm.get('state')?.touched && employerForm.get('state')?.invalid"
        class=" alert alert-danger">
        {{ employerForm.get('country')?.value === 'United States' ? 'Please select a state.' : 'Please enter a valid state/province.'}}
      </div>
      </div>

      <!-- ZIP Code (With ZIP+4 for USA) -->
      <div class="mb-3">
        <label  class="form-label">
          {{employerForm.get('country')?.value === 'United States' ? 'Zip Code': 'Zip Code/Postal Code'}}
        <span *ngIf= "employerForm.get('country')?.value === 'United States'" class = "text-danger">*</span>
      </label>
        <div class="d-flex">
          <!-- USA ZIP Code Input -->
          <input type="text" id="zip" class="form-control" formControlName="zip" 
           [placeholder]="employerForm.get('country')?.value === 'United States' ? 'Enter Zip Code': 'Enter Zip Code/Postal Code'"
            style="width: 50%;" 
            autocomplete="off" 
            [attr.maxlength]="employerForm.get('country')?.value === 'United States' ? '5' : '20'"
            [attr.pattern]="employerForm.get('country')?.value === 'United States' ? '^[0-9]{5}$' : '^[A-Za-z0-9 ]{1,20}$'"
            [attr.inputmode]="employerForm.get('country')?.value === 'United States' ? 'numeric' : 'text'"
            (keypress)="employerForm.get('country')?.value === 'United States' ? $event.charCode >=48 &&  $event.charCode <=57 :true"
            [disabled]="isSubsequentSubmission && isFormDisabled || !isEditingAddress" />
          <!-- Show ZIPExt for USA Only -->
          <span *ngIf="employerForm.get('country')?.value === 'United States'" class="mx-2">-</span>
          <input *ngIf=  "employerForm.get('country')?.value === 'United States'" type="text" id="zipExt" class="form-control" formControlName="zipExt"
            placeholder="Enter Zip Ext" pattern="^[0-9]{4}$" maxlength="4" style="width: 30%;" autocomplete="off"
            inputmode="numeric" (keypress)=" $event.charCode >=48 &&  $event.charCode <=57"
            [disabled]="isSubsequentSubmission && isFormDisabled || !isEditingAddress" />
        </div>
        <!-- ZIP Code Validation -->
        <div *ngIf="employerForm.get('zip')?.touched && employerForm.get('zip')?.errors">
          <div *ngIf="employerForm.get('zip')?.errors?.['required']" class="alert alert-danger">
            Zip code is required.
          </div>
          <div *ngIf="employerForm.get('zip')?.errors?.['pattern']" class="alert alert-danger">
            Please enter an valid 5-digit Zip code.
          </div>
        </div>
        <div *ngIf="employerForm.get('country')?.value === 'United States'" >
          <div *ngIf="employerForm.get('zipExt')?.touched && employerForm.get('zipExt')?.errors">
            <div *ngIf="employerForm.get('zipExt')?.errors?.['required']" class="alert alert-danger">
              Zip Ext code is required.
            </div>
  
          <div *ngIf="employerForm.get('zipExt')?.errors?.['pattern']" class="alert alert-danger">
            Please enter an valid 4-digit Zip Ext code.
          </div>
        </div>
        </div>
       
    </div>
    </div>

    <div>
      <label for="notes" class="form-label">Notes</label>
      <textarea id="notes" rows="2" class="form-control notes-field" maxlength="500" placeholder="Enter your notes"
        formControlName="notes" autocomplete="off" oninput="this.value = this.value.trimStart().replace()"></textarea>
    </div>
    <div>
      <label class="form-label">Do you require adding representative's name, email, and phone number?
        </label>
    </div>
    <div class="form-check form-check-inline">
      <input type="radio" id="requireYes" class="form-check-input" formControlName="addRepresentative" [value]="'Yes'"
        (change)="toggleRepresentativeFields('Yes')" />
      <label class="form-check-label" for="requireYes">Yes</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="radio" id="requireNo" class="form-check-input" formControlName="addRepresentative" [value]="'No'"
        (change)="toggleRepresentativeFields('No')" />
      <label class="form-check-label" for="requireNo">No</label>
    </div>

    <!-- Conditionally show additional fields -->
    <div *ngIf="showRepresentativeFields">
      <div>
        <label for="repName" class="form-label">Name of Representative <span class="text-danger">*</span></label>

        <input type="text" id="repName" class="form-control" formControlName="repName" maxlength="100"
          placeholder="Enter Name of Representative" autocomplete="off"
          oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z\s]/g, '')" required />
        <div *ngIf="employerForm.get('repName')?.touched && employerForm.get('repName')?.invalid"
          class="alert alert-danger">

          Please enter a name.
        </div>
      </div>
      <div>
        <label for="repEmail" class="form-label">Representative Email Address
          <span class="text-danger">*</span></label>
        <input type="text" id="repEmail" class="form-control" formControlName="repEmail" placeholder="name@domain.com"
          autocomplete="off" maxlength="100" [disabled]="isSubsequentSubmission && isFormDisabled" required />
        <div *ngIf="employerForm.get('repEmail')?.touched && employerForm.get('repEmail')?.invalid"
          class="alert alert-danger">
          Please enter an email address.
        </div>
      </div>
      <div>
        <label for="repPhone" class="form-label">Representative Phone Number <span class="text-danger">*</span></label>
        <div class="input-group">
          <input type="text" id="repCountryCode" class="form-control country-code" formControlName="repCountryCode"
            maxlength="3" autocomplete="off" inputmode="numeric" (keypress)="validateCountryCode($event)"
            style="max-width: 60px" [disabled]="isSubsequentSubmission && isFormDisabled" />
          <input type="text" id="repPhone" class="form-control" formControlName="repPhone"
            placeholder="Enter Phone Number" mask="(000) 000-0000" autocomplete="off" [disabled]="isFormDisabled"
            required />

        </div>
        <div *ngIf="employerForm.get('repPhone')?.touched && employerForm.get('repPhone')?.invalid"
          class="alert alert-danger">
          Please enter phone number.
        </div>
      </div>
    </div>
    <div class="pt-10">
      <button type="submit" class="btn btn-primary btn-lg float-right" (click)=" onSubmit()">
        Next
      </button>
      <button type="button" (click)="onReset()" class="btn btn-warning btn-lg float-left">
        Back
      </button>
    </div>
  </form>
</div>