<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" *ngIf="fileToDelete">{{fileToDelete}}?</h4>
     <h4 class="modal-title" id="modal-basic-title" *ngIf="!fileToDelete"></h4>
  </div>
  <div class="modal-body">
   <p *ngIf="dataToTakeAsInput">{{dataToTakeAsInput}}</p>
   
    <div class="form-group">
        <button type="submit" (click)="confirm()" class="btn btn-primary" >Yes</button>
        <button
          type="button"
          (click)="cancel()"
          class="btn btn-warning float-right"
        >
          No
        </button>
      </div>
  </div>