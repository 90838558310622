import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { validateERCaseID } from '../models/validate.model';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EAuditLandingPageService {
  private baseUrl = environment.validateURL;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'X-Frame-Options': 'SAMEORIGIN',
      // 'Content-Security-Policy': "frame-ancestors 'self'",
   
    })
  };

  validateERCaseID(data: validateERCaseID): Observable<any> {
    let userData = {
      userName: "DOLExternalWebApp",
      request: {
        domainName: environment.domainName,
        intakeValidators: [
          {
            domainKey: "erNumber",
            domainValue: data.erNumber
          },
          {
            domainKey: "caseId",
            domainValue: data.caseId
          }
        ]
      }
    }

    return this.http.post(this.baseUrl + 'docprocessor/api/v1/docintakevalidation/config/validate', userData, this.httpOptions).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }
}