<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" >How to Zip (Compress) and Unzip Files</h4>
    <button type="button" class="btn-close"  aria-label="Close"  (click)="cancel()" >
      <i class="bi bi-x"></i>
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>To zip (compress) files:</strong></p>
    <p>In the search box on the taskbar, type <strong>File Explorer</strong>, and then select it from the list of results. Right-click the file you want to zip, and then select <strong>Send to > Compressed (zipped) folder</strong>.</p>
    <p><strong>Below Reference Image:</strong></p>
    <img src = "../../assets/zipimage.jpg" alt = "zip" />

    <p><strong>To Unzip files:</strong></p>
    <ul>
    <li>Open <strong>File Explorer</strong> and find the compressed folder.</li>
    <li>To unzip the entire folder, right-click to select <strong>Extract All</strong>, and then follow the instructions.</li>
    <li>You can also select <strong>Extract All</strong> from the command ribbon.</li>
    <li>To decompress a single file or folder, double-click the compressed folder to open it. Then, drag or copy the item from the compressed folder to a new location.</li>
    </ul>
   
    
  </div>
  <div class="modal-footer">
    <button
    type="button"
    (click)="cancel()"
    class="btn btn-warning float-right"
  >
    Cancel
  </button>
    </div>