import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-address-confirm-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.scss']
})
export class AddressPopupComponent {
  @Input() message: string;
  @Input() updateDetails:boolean = true;
  constructor(public activeModal: NgbActiveModal) {}
  confirm() {
    this.activeModal.close(true);  // Return true when confirmed
  }
  cancel() {
    this.activeModal.close(false); // Return false when canceled
  }
 }