export const environment = {
    production: false,
    siteKey: '6LffnmIpAAAAABRdRvOKDd1JHt6hwL0Qqz50aMvm',
    // validateURL: 'https://docintake-validation-service-gmboqmwosa-uk.a.run.app/',

    validateURL: 'https://docintakeapi-gateway1-b16ogt9t.uk.gateway.dev/',
    //uploadURL:'https://docintake-single-doc-service-gmboqmwosa-uk.a.run.app/docupload/api/v1',
    //apigateway url
    uploadURL:'https://docintakeapi-gateway1-b16ogt9t.uk.gateway.dev',
 
    //submissionUrl: 'https://docintake-submission-service-gmboqmwosa-uk.a.run.app/docprocessor/api/v1/submission',
    submissionUrl: 'https://docintakeapi-gateway1-b16ogt9t.uk.gateway.dev',
    
    authUrl:'https://us-east4-dol-ui-docintake-dev.cloudfunctions.net/authtok',
    domainName: "eaudit",


    //per submssion
    maxFileCount :"50",
    maxFileSizeMB:"100",
    maxTotalSizeMB:"1400",
	fileSupported: "png,jpg,jpeg,docx,doc,txt,pdf,xls,xlsx,png,gif,mp4,mp3,zip",
	
	
	// Currently files size of 20 MB or less is considered small
    perUploadMaxFileCount:"10",
    perUploadMaxSmallFileCnt : "45",
    perUploadMaxSmallFileSizeMB : "20",
    perUploadMaxSmallFileTotalSizeMB : "900",
	
	// Files size over 20 MB is considered large
    perUploadMaxLargeFileCnt : "1",
    perUploadMaxLargeSizeMB :"100",
	perUploadmaxLargeFileTotalSizeMB : "500"
};
